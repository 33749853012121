import React from "react";
import SignPage from "../../components/register-login-page/SignPage";

function LoginPage() {
  return (
    <>
      <SignPage />
    </>
  );
}

export default LoginPage;
